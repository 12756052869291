<template>
  <v-row justify="center">
    <v-col xs="12" sm="10">
      <v-container>
        <v-row>

          <v-col cols="12" xs="12" lg="10" xl="10">
            <div class="d-flex align-center">
              <v-toolbar class="transparent elevation-0">
                <v-btn icon @click="$router.push('/wallet');">
                  <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
                </v-btn>
                <v-toolbar-title> Wallet</v-toolbar-title>
              </v-toolbar>
            </div>
          </v-col>

          <h4>Transaction History</h4>

          <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent">
            <v-tab value="one">Deposit</v-tab>

            <v-tab-item>
              <v-card color="#272727" class="mt-5 pa-5" style="border-radius: 10px;">
                <v-card-text>

                  <v-data-table :loading="loading" :headers="headers" :items="depositHistory" :items-per-page="10"
                    class="transparent">
                    <template v-slot:item.name="{ item }">

                      <div class="d-flex align-center py-4">

                        <div class="mr-2">
                          <v-avatar color="white" v-if="item.coin == 'USDT'" size="25">
                            <v-img
                              src="https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png"></v-img>
                          </v-avatar>
                          <v-avatar color="white" v-if="item.coin == 'BTC'" size="25">
                            <v-img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"></v-img>
                          </v-avatar>
                          <v-avatar color="white" v-if="item.coin == 'ETH'" size="25">
                            <v-img src="https://cryptologos.cc/logos/ethereum-eth-logo.png"></v-img>
                          </v-avatar>
                          <v-avatar color="white" v-if="item.coin == 'UCC'" size="25">
                            <v-img src="https://uccoin.co/img/gmswap-logo.e19467db.png"></v-img>
                          </v-avatar>
                        </div>

                        <div>
                          <span>{{ item.coin }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-slot:item.status="{ item }">
                      <div>
                        <v-chip small v-if="item.recharge_status == 'APPROVED'" class="ma-2" color="green"
                          text-color="white">
                          Completed
                        </v-chip>

                        <v-chip small v-else="item.recharge_status == 'APPROVED'" class="ma-2" color="primary">
                          Pending
                        </v-chip>
                      </div>
                    </template>

                    <template v-slot:item.amount="{ item }">
                      <v-row>
                        <span>
                          {{ parseFloat(item.recharge_amount).toFixed(3) }}
                        </span>
                      </v-row>
                    </template>

                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab value="two">Withdrawal</v-tab>
            <v-tab-item>
              <v-card color="#272727" class="mt-5 pa-5" style="border-radius: 10px;">
                <v-card-text>

                  <div v-if="withdrawHistory.length > 0">
                    <v-data-table :headers="withdrawHeaders" :items="withdrawHistory" :items-per-page="10"
                      class="transparent">
                      <template v-slot:item.name="{ item }">

                        <v-row>
                          <div class="d-flex align-center py-4">

                            <div class="mr-2">
                              <v-avatar color="white" v-if="item.coin == 'USDT'" size="25">
                                <v-img
                                  src="https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png"></v-img>
                              </v-avatar>
                              <v-avatar color="white" v-if="item.coin == 'BTC'" size="25">
                                <v-img
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"></v-img>
                              </v-avatar>
                              <v-avatar color="white" v-if="item.coin == 'ETH'" size="25">
                                <v-img src="https://cryptologos.cc/logos/ethereum-eth-logo.png"></v-img>
                              </v-avatar>
                            </div>

                            <div>
                              <span>{{ item.coin }}</span>
                            </div>
                          </div>
                        </v-row>

                      </template>
                      <template v-slot:item.amount="{ item }">
                        <v-row>
                          <span>
                            {{ parseFloat(item.withdraw_amount).toFixed(3) }}
                          </span>

                        </v-row>
                      </template>
                      <template v-slot:item.trc="{ item }">
                        <v-row>
                          <span>
                            {{ item.address }}
                          </span>

                        </v-row>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-chip small v-if="item.state == 'PROCEED'" class="ma-2" color="green" text-color="white">
                          Completed
                        </v-chip>

                        <v-chip small v-if="item.state == 'ONHOLD'" class="ma-2" color="danger">
                          Pending
                        </v-chip>

                        <v-chip small v-if="item.state == 'FREEZE'" class="ma-2" color="red">
                          Freeze
                        </v-chip>

                        <v-chip small v-if="item.state == 'FLOATING'" class="ma-2" color="yellow">
                          FLOATING
                        </v-chip>
                      </template>
                    </v-data-table>
                  </div>

                  <div v-else class="text-center">
                    <span>No Transactions</span>
                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab value="three">Buy</v-tab>
            <v-tab-item>
              <v-card color="#272727" class="mt-5 pa-5" style="border-radius: 10px;">
                <v-card-text>

                  <div v-if="buyHistory.length > 0">
                    <v-data-table :loading="loading" :headers="buyHeaders" :items="buyHistory" :items-per-page="10"
                      class="transparent">
                      <template v-slot:item.name="{ item }">

                        <div class="d-flex align-center py-4">

                          <!-- <div class="mr-3">
                            <v-avatar size="25">
                              <v-img :src="item.image"></v-img>
                            </v-avatar>
                          </div> -->

                          <div>
                            <span>{{ item.coin_pair }}</span>
                          </div>
                        </div>

                        <!-- <v-row>
                  <v-col cols="2" class="text-center" style="margin-top:2px">
                    <v-avatar size="25">
                      <v-img :src="item.image"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="6">
                    <span style="margin-top:50px">{{ item.coin_pair }}</span><br />
                    <span style="font-size:10px;color:grey">{{ (item.coin_pair).replace('USDT', '') }}</span>
                    <span> {{ item.date }}</span><br />
                  </v-col>
                </v-row> -->

                      </template>

                      <template v-slot:item.price="{ item }">
                        <!-- {{ parseFloat(item.final_price).toFixed(2) }} -->
                        {{ formattedPrice(item.final_price) }}
                      </template>

                      <template v-slot:item.status="{ item }">

                        <v-chip small v-if="item.state == 'APPROVED'" class="ma-2" color="green" text-color="white">
                          Filled
                        </v-chip>

                        <v-chip small v-if="item.state == 'DISSAPROVED'" class="ma-2" color="red">
                          Cancelled
                        </v-chip>
                      </template>

                      <template v-slot:item.amount="{ item }">
                        {{ formattedAmount(item.number_of_order) }}
                        <!-- {{ parseFloat(item.number_of_order).toFixed(4) }} -->
                      </template>


                    </v-data-table>
                  </div>

                  <div v-else class="text-center">
                    <span>No Transactions</span>
                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab value="four">Sell</v-tab>
            <v-tab-item>
              <v-card color="#272727" class="mt-5 pa-5" style="border-radius: 10px;">
                <v-card-text>

                  <div v-if="sellHistory.length > 0">
                    <v-data-table :headers="sellHeaders" :items="sellHistory" :items-per-page="10" class="transparent">

                      <template v-slot:item.name="{ item }">

                        <div class="d-flex align-center py-4">

                          <!-- 
                          <div class="mr-3">
                            <v-avatar size="25">
                              <v-img :src="item.image"></v-img>
                            </v-avatar>
                          </div> -->

                          <div>
                            <span>{{ item.coin_pair }}</span>
                          </div>
                        </div>

                      </template>

                      <template v-slot:item.price="{ item }">
                        {{ formattedPrice(item.final_price) }}
                      </template>

                      <template v-slot:item.amount="{ item }">
                        {{ formattedAmount(item.number_of_order) }}

                      </template>

                      <template v-slot:item.status="{ item }">

                        <v-chip small v-if="item.state == 'APPROVED'" class="ma-2" color="green" text-color="white">
                          Filled
                        </v-chip>

                        <v-chip small v-if="item.state == 'DECLINED'" class="ma-2" color="red">
                          Cancelled
                        </v-chip>
                      </template>

                    </v-data-table>
                  </div>
                  <div v-else class="text-center">
                    <span>No Transactions</span>
                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>

import numeral from 'numeral'
import { getDepositHistory, getWithdrawHistory, getBuyHistory, getSellHistory } from '@/services/historyServices';

export default {

  data: () => ({
    headers: [
      {
        value: "name",
        text: "Name",
        align: "start",
        sortable: true,
      },
      {
        value: "date",
        text: "Date",
        sortable: true,
        align: "start",
      },

      {
        value: "status",
        text: "Status",
        sortable: true,
        align: "center",
      },

      

      {
        value: "amount",
        text: "Amount",
        sortable: true,
        align: "end",
      },
    ],

    withdrawHeaders: [
      {
        value: "name",
        text: "Name",
        align: "start",
        sortable: true,
      },
      {
        value: "date",
        text: "Date",
        sortable: true,
        align: "start",
      },
      {
        value: "trc",
        text: "Address",
        sortable: true,
        align: "end",
      },
      {
        value: "status",
        text: "Status",
        sortable: true,
        align: "center",
      },
      {
        value: "amount",
        text: "Amount",
        sortable: true,
        align: "end",
      },

    ],

    buyHeaders: [
      {
        value: "name",
        text: "Name",
        align: "start",
        sortable: true,
      },
      // {
      //   value: "coin_pair",
      //   text: "Coin Pair",
      //   sortable: true,
      //   align: "start",
      // },
      {
        value: "price",
        text: "Price",
        align: "start",
        sortable: true,
      },

      {
        value: "status",
        text: "Status",
        sortable: true,
        align: "center",
      },

      {
        value: "amount",
        text: "Amount",
        align: "end",
        sortable: true,
      },

    ],

    sellHeaders: [
      {
        value: "name",
        text: "Name",
        align: "start",
        sortable: true,
      },
      {
        value: "price",
        text: "Price",
        align: "start",
        sortable: true,
      },

      {
        value: "status",
        text: "Status",
        sortable: true,
        align: "center",
      },

      {
        value: "amount",
        text: "Amount",
        align: "end",
        sortable: true,
      },

    ],
    tab: "tab-0",
    histories: [],
    depositHistory: [],
    withdrawHistory: [],
    sellHistory: [],
    buyHistory: [],
    loading: true,
    timer1: null,
    timer2: null,
    timer3: null,
    timer4: null,
  }),

  computed: {
    formattedPrice() {
      return (price) => numeral(price).format('$0,0.00'); // Format price as currency
    },

    formattedAmount() {
      return (value) => numeral(value).format('0.00'); // Format volume as whole number
    },

  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchDepositHistory() {
      this.loading = true
      try {
        let depositHistory = await getDepositHistory()
        localStorage.setItem('depositHistory', JSON.stringify(depositHistory));

        this.depositHistory = depositHistory
      } catch (error) {
        console.error('Failed to deposit history', error);
      } finally {
        this.loading = false
      }
    },

    async fetchWithdrawHistory() {
      this.loading = true
      try {
        let withdrawHistory = await getWithdrawHistory()
        localStorage.setItem('withdrawHistory', JSON.stringify(withdrawHistory));
        this.withdrawHistory = withdrawHistory
      } catch (error) {
        console.error('Failed to withdraw history', error);
      } finally {
        this.loading = false
      }
    },

    async fetchBuyHistory() {
      this.loading = true
      try {
        let buyHistory = JSON.parse(localStorage.getItem('buyHistory'));
          buyHistory = await getBuyHistory()
          localStorage.setItem('buyHistory', JSON.stringify(buyHistory));
        this.buyHistory = buyHistory
      } catch (error) {
        console.error('Failed to buy history', error);
      } finally {
        this.loading = false
      }
    },

    async fetchSellHistory() {
      this.loading = true
      try {
        let sellHistory = JSON.parse(localStorage.getItem('sellHistory'));

          sellHistory = await getSellHistory()
          localStorage.setItem('sellHistory', JSON.stringify(sellHistory));
        this.sellHistory = sellHistory
      } catch (error) {
        console.error('Failed to sell history', error);
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.fetchDepositHistory();
    this.fetchWithdrawHistory();
    this.fetchBuyHistory();
    this.fetchSellHistory();
  },
};
</script>
<style scoped>
p {
  font-size: smaller !important;
}

#balance-card {
  margin-top: 20px !important;
  border-radius: 30px 30px 0 0 !important;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
